import './App.css';
import Home from './Home';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Thankyou from './pages/Thankyou';
import About from './pages/About';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import useGTMPageView from './hooks/useGTMPageView';
import { useEffect } from 'react';

function App() {
  // Custom hook to track page views
  function TrackPageViews() {
    const location = useLocation(); // Hook from react-router-dom to access the current location
    useGTMPageView(location); // Passing location to your custom hook
    return null; // This component does not render anything
  }

  return (
    <div className="App">
      <Router>
        {/* Call TrackPageViews here to ensure it re-renders on route changes */}
        <TrackPageViews />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
