// src/hooks/useGTMPageView.js
import { useEffect } from 'react';

const useGTMPageView = (location) => {
  useEffect(() => {
    const { pathname, search } = location;
    const pagePath = pathname + search;

    window.dataLayer.push({
      event: 'pageview',
      page: pagePath,
    });
  }, [location]);
};

export default useGTMPageView;
