import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Trust1 from '../images/top-banner-img/trust-pilot.png'
import Trust2 from '../images/top-banner-img/bbb-logo.png'
import Trust3 from '../images/top-banner-img/mcafee.png'
import Form from '../components/Form'

const Thankyou = () => {
  return (
    <>
    <Navbar />
    <section id="home" class="home">
        <div class="banner_wrapper wrapper">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 order-md-1 order-2">
                        <h3>Thankyou </h3>
                        
                        {/* <a href="#" class="main-btn mt-4 fill-btn">Call Us for Complete Assistance</a> */}
                        {/* <a href="#" class="main-btn mt-4 ms-3">Learn More</a> */}
                    </div>
                    <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-5">
                        
                        {/* <div class="top-right-sec">
                            <div class="animate-img">
                                <img decoding="async" class="aimg1" src="./images/top-banner-img/woman-brush.png" />
                                <img decoding="async" class="aimg2" src="./images/top-banner-img/doctor.png" />
                            </div>
                            <img decoding="async" class="img-fluid ms-xl-5" src="./images/top-banner-img/top-right-img-1.png" />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        {/* <div class="wrapper pb-0">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust1} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★☆
                        </div>
                    </div>
                </div>
                        <h4>4.7 Trustpilot Rating</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust2} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★★
                        </div>
                    </div>
                </div>
                                <h4>5 BBB Rating</h4>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust3} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★★
                        </div>
                    </div>
                </div>
                                <h4>Approved from Trusted Site</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </section>


    <section id="testimonial" class="testimonial_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                    <h3 class="text-black">Thankyou for filling up the form</h3>
                </div>
            </div>
            <div class="row align-items-center">
                {/* <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img decoding="async" src="./images/testimonial/client1.png" class="img-fluid" />
                        <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                        <p>CEO of Prime IT</p>
                    </div>
                </div> */}
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    <h4>Our one of technician will get back to you soon to resolve all your problems. Have a nice day.</h4>
                    <a href="+18888301368" class="mt-5 main-btn fill-btn">24X7 Tollfree Support</a>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </>
  )
}

export default Thankyou