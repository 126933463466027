import React from 'react';
import './CallLogo.css';
import callIcon from '../images/telephone.png'; // Update with the path to your call icon image

const CallLogo = () => {

  const handleLogoClick = () => {
    window.dataLayer.push({
      event: 'callLogoClick',
      // Additional data can be added here if needed
    });
  };


  return (
    <div className="call-logo-container">
      <a href="tel:+18888301368" className="call-logo-link" onClick={handleLogoClick}>
        <img src={callIcon} alt="Call Us" className="call-logo" />
        <span className="call-text">24X7 Toll Free Number</span>
        <span className='d-none'>+18888301368</span>
      </a>
    </div>
  );
};

export default CallLogo;
