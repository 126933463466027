import React from 'react'
import Form from './components/Form'
import Trust1 from './images/top-banner-img/trust-pilot.png'
import Trust2 from './images/top-banner-img/bbb-logo.png'
import Trust3 from './images/top-banner-img/mcafee.png'
import About1 from './images/about/about-banner1.png'

import SVG1 from './images/services/laptop.png'
import SVG2 from './images/services/gaming.png'
import SVG3 from './images/services/devices.png'
import SVG4 from './images/services/smart-phone.png'
import SVG5 from './images/services/migrating.png'
import SVG6 from './images/services/cross.png'
import Team1 from './images/team/team1.png'
import Team2 from './images/team/team2.png'
import Team3 from './images/team/team3.png'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import Gallery1 from './images/gallery/11.jpg'
import Gallery2 from './images/gallery/22.jpg'
import Gallery3 from './images/gallery/33.jpg'
import Gallery4 from './images/gallery/44.jpg'
import Gallery5 from './images/gallery/55.jpg'
import Client from './images/testimonial/client.png'
import Why1 from './images/blog/4.jpg'
import Why2 from './images/blog/5.jpg'
import Why3 from './images/blog/6.jpg'
import Faq from './images/faq.jpg'
import Tag1 from './images/customer-service.png'
import Tag2 from './images/workforce.png'
import Tag3 from './images/enterprice.png'
import PopupForm from './components/PopupForm'
import Form2 from './components/Form2'
import PopupForm2 from './components/PopupForm2'
import CallLogo from './components/CallLogo'

const Home = () => {
  return (
    <>

    <body data-bs-spy="scroll" data-bs-target=".navbar" data-bs-offset="100">

        <CallLogo />

    {/* <!-- Navbar section --> */}
    <Navbar />

    {/* <!-- Navbar section exit --> */}

    {/* <!-- Banner section --> */}
    <section id="home" class="home">
        <div class="banner_wrapper wrapper">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 order-md-1 order-2">
                        <h3>GET 24/7 COMPREHENSIVE </h3>
                        <h1>Tech Assistance</h1>
                        <p>for your pc & laptop instant assistance anytime, anywhere!</p>
                        <a href="tel:+18888301368" class="main-btn mt-4 fill-btn">Call Us Tollfree for Complete Assistance</a>
                        {/* <a href="#" class="main-btn mt-4 ms-3">Learn More</a> */}
                    </div>
                    <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-5">
                       
                        {/* <div class="top-right-sec">
                            <div class="animate-img">
                                <img decoding="async" class="aimg1" src="./images/top-banner-img/woman-brush.png" />
                                <img decoding="async" class="aimg2" src="./images/top-banner-img/doctor.png" />
                            </div>
                            <img decoding="async" class="img-fluid ms-xl-5" src="./images/top-banner-img/top-right-img-1.png" />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        {/* <div class="wrapper pb-0">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust1} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★☆
                        </div>
                    </div>
                </div>
                        <h4>4.7 Trustpilot Rating</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust2} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★★
                        </div>
                    </div>
                </div>
                                <h4>5 BBB Rating</h4>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust3} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★★
                        </div>
                    </div>
                </div>
                                <h4>Approved from Trusted Site</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </section>

    {/* <!-- Banner section exit --> */}

    {/* <!-- About section --> */}
    <section id="about" class="about_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mb-mb-0 mb-5">
                    <div class="position-relative">
                        {/* <img decoding="async" src={About1} class="img-fluid" /> */}
                        <Form />
                        {/* <img decoding="async" src={About2} class="about-animate" /> */}
                    </div>
                </div>
                <div class="col-md-6 text-center text-md-start">
                <h2>With Easy Comp Assist as your IT partner, you will get:</h2>
                    <p>Anticipatory IT Maintenance: Easy Comp Assist vigilantly oversees your tech infrastructure to pre-empt issues, ensuring consistent operational flow and reducing interruptions.</p>
                    <p>Accessible Expert Knowledge: Our IT experts stand by, offering swift and adept resolutions to tech queries, ensuring your business's tech needs are met promptly..</p>
                    <p>Customized Technological Planning: Recognizing each business's distinct needs, Easy Comp Assist devises bespoke IT strategies that assistance your specific objectives and expansion plans.</p>
                    <p>Innovative Tech Advancement: With Easy Comp Assist, embrace the forefront of technology, equipping your enterprise with leading-edge tools and innovations for a competitive edge.</p>
                    <div class="mt-5 card">
                        <div class="about-clinic">
                            <h4>Laptop/PC<br></br> Assist</h4>
                            
                        </div>
                        <div class="about-clinic">
                            <h4>iOS/Android Assist</h4>
                            
                        </div>
                        <div class="about-clinic">
                            <h4>Tablets<br></br> Assist</h4>
                            
                        </div>
                        <div class="about-clinic">
                            <h4>Printer <br></br> Assist</h4>
                            
                        </div>
                        <span class="line-1"></span>
                        <span class="line-2"></span>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- About section exit --> */}

    {/* <!-- Services section -->  */}
    <section id="services" class="services_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3>Our Specialized Services</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card">
                        <div class="icon-box">
                            <img decoding="async" src={SVG1} />
                        </div>
                        <div>
                            <h4>Laptop & Computer Assist</h4>
                            <p>Your go-to for expert tech assistance. We ensure your devices run smoothly. Reliable assistance just for you!</p>
                            <PopupForm />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card">
                        <div class="icon-box">
                            <img decoding="async" src={SVG2} />
                        </div>
                        <div>
                            <h4>PC & MAC Assist</h4>
                            <p>Your tech assistance experts for both platforms. We fix issues, boost performance, and keep your devices running smoothly.</p>
                            {/* <a href="#" class="main-btn mt-4">Submit the form for assistance</a> */}
                            <PopupForm />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card">
                        <div class="icon-box">
                            <img decoding="async" src={SVG3} />
                        </div>
                        <div>
                            <h4>Tablet Assist</h4>
                            <p>Your trusted assistance for tablets. We resolve issues, enhance performance, and ensure your tablets run smoothly. Expert help awaits!</p>
                            <PopupForm />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card">
                        <div class="icon-box">
                            <img decoding="async" src={SVG4} />
                        </div>
                        <div>
                            <h4>IOS & Android Assist</h4>
                            <p>Expert assistance for both platforms. We resolve issues, optimize performance, and keep your devices at their best.</p>
                            <PopupForm />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card">
                        <div class="icon-box">
                            <img decoding="async" src={SVG5} />
                        </div>
                        <div>
                            <h4>Network Setup Assist</h4>
                            <p>Your reliable partner for network setup and optimization. We ensure seamless connections and troubleshoot any issues efficiently.</p>
                            <PopupForm />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card">
                        <div class="icon-box">
                            <img decoding="async" src={SVG6} />
                        </div>
                        <div>
                            <h4>Printer Assist</h4>
                            <p>Your trusted solution for printer issues. We troubleshoot, optimize performance, and make sure your printing is smooth and efficient.</p>
                            <PopupForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Services section Exit --> */}

    {/* <!-- Blog section -->  */}
    <section id="blog" class="blog_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3 class="text-black">Why Choose Us</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card p-0 border-0 rounded-0">
                        <img decoding="async" src={Why1} />
                        <div class="blog-content">
                            <h5 class="text-white mb-4">Expertise Across Devices</h5>
                            <h6 class="text-white"></h6>
                            <p class="mt-2 text-white">Our team possesses deep technical knowledge across a wide range of devices including laptops, PCs, mobiles, and tablets, ensuring versatile and comprehensive assistance for all your tech needs.
                            </p>
                            {/* <a href="#" class="main-btn mt-2">Read More</a> */}

                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card p-0 border-0 rounded-0">
                        <img decoding="async" src={Why2} />
                        <div class="blog-content">
                            <h5 class="text-white mb-4">Responsive Customer Service</h5>
                            
                            <p class="mt-2 text-white">We prioritize your convenience and satisfaction, offering swift, attentive, and personalized customer service to address your tech issues without delay.
                            </p>
                            {/* <a href="#" class="main-btn mt-2">Read More</a> */}

                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card p-0 border-0 rounded-0">
                        <img decoding="async" src={Why3} />
                        <div class="blog-content">
                            <h5 class="text-white mb-4">Innovative Solutions</h5>
                            
                            <p class="mt-2 text-white">Leveraging the latest technological advancements, we provide innovative and effective solutions to enhance your device's performance and extend its lifespan, making us a leading choice for tech assistance.
                            </p>
                            {/* <a href="#" class="main-btn mt-2">Read More</a> */}

                        </div>
                    </div>
                </div>
                <a href="tel:+18888301368" class="main-btn mt-4 fill-btn">Call Us for Complete Assistance</a>
            </div>
        </div>
    </section>

    {/* <!-- Blog Section Exit  --> */}

    {/* <!-- Pricing section --> */}
    <section id="pricing" class="price_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center mb-5">
                    <p class="pricing_subtitle">A plan for everyone</p>
                    <h2 class="pricing_title">OUR PRICING</h2>
                </div>
            </div>
            <div class="row align-items-center px-3 px-lg-0">
                <div class="col-sm-6 col-lg-4">
                    <div class="pricing_card pricing_one">
                        <p class="pricing_period">Starter</p>
                        <img decoding="async" src={Tag1} class="img-fluid" />
                        <p class="pricing_rate">$15</p>
                        <p class="pricing_text">monthly</p>
                        <div class="pricing_all_plan">
                            <ul>
                                <li className='display-2'>Help Desk assistance:</li>
                                <p>Enjoy round-the-clock assistance with unlimited access to phone, chat, and email assistance, ensuring optimal device functionality with performance tuning and expert help for software installation.</p>
                                <li>Infected clean up protection.</li>
                                <p>Enhance your device's security with comprehensive infected files removal, along with robust protection services to keep your system safeguarded.</p>
                                
                            </ul>
                        </div>
                        <PopupForm2 />
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4 position-relative">
                    <div class="pricing-ribbon"><span>Most Popular</span></div>
                    <div class="pricing_card featured">
                    <p class="pricing_period">Professional</p>
                        <img decoding="async" src={Tag2} class="img-fluid" />
                        <p class="pricing_rate">$49</p>
                        <p class="pricing_text">monthly</p>
                        <div class="pricing_all_plan medium-plan-features-section">
                            <ul>
                                <li>All basic features</li>
                                <li>Comprehensive Security Suite</li>
                                <p>Offers a robust combination of antivirus, VPN, firewall, among other security features, paired with automatic data backup and employee activity monitoring for complete peace of mind.</p>
                                <li>Streamlined Onboarding and Offboarding</li>
                                <p>Facilitates seamless integration of new employees and secure offboarding processes, including computer lockdown and home network security checks.</p>
                                <li>Data Assurance</li>
                                <p>Provides solutions for data recovery and remote migration, ensuring business continuity with strategic disaster recovery planning, including Recovery Time Objectives (RTO) and Recovery Point Objectives (RPO).</p>
                                <li>Network Integrity and Education</li>
                                <p>Conducts regular network security assessments, sets up secure VPNs, and educates users on security best practices to mitigate risks and enhance protection.</p>
                            </ul>
                        </div>
                        {/* <a class="learn-more-btn btn-featured" href="#contact">Get Started</a> */}
                        <PopupForm2 />
                    </div>
                </div>
                <div class="col-sm-6 col-lg-4">
                    <div class="pricing_card">
                        <p class="pricing_period">Enterprice</p>
                        <img decoding="async" src={Tag3} class="img-fluid" />
                        <p class="pricing_rate">$79</p>
                        <p class="pricing_text">monthly</p>
                        <div class="pricing_all_plan">
                            <ul>
                                <li>All professional features</li>
                                <li>Enhanced Monitoring & Cybersecurity:</li>
                                <p>Includes deep darkweb surveillance for potential data leaks, sophisticated email and web content security screenings, and comprehensive firewall management with regular updates.</p>
                                <li>Data Compliance & Privacy Strategy:</li>
                                <p>Offers expert guidance on establishing a robust data security and privacy program, thorough reviews of website authentication processes and privacy policies, and consistent scans to identify and address system vulnerabilities.</p>
                                <li>Dedicated IT Manager</li>
                            </ul>
                        </div>
                        <PopupForm2 />
                    </div>
                </div>
            </div> 
        </div>

        {/* <!-- Free Trial --> */}
        {/* <div class="free_trial">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-sm-12 col-lg-6 text-center text-lg-start">
                        <h2 class="free_title">Ready to get started now with our multipurpose landing page?</h2>
                    </div>
                    <div class="col-sm-12 col-lg-6 text-center text-lg-end mt-4 mt-lg-0"> 
                        <a class="learn-more-btn" href="#contact"><i class="fa fa-rocket" aria-hidden="true"></i> Start a free trial</a> 
                    </div>
                </div>
            </div>
        </div> */}
    </section>

    {/* <!-- Pricing section exit --> */}

    {/* <!-- Team section -->  */}
    <section id="team" class="team_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3 class="text-black">Our Team Leaders</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                        <div class="team-img">
                            <img decoding="async" src={Team1} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                            <h5>Darry Milin</h5>
                             <p>Chief Operating Officer</p>
                            
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                        <div class="team-img">
                            <img decoding="async" src={Team2} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                            <h5>Salman Ahmed</h5>
                            <p>Chief Executive Officer</p>
                            {/* <ul class="social-network">
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                                <li><a href="#"><i class="fab fa-vimeo-v"></i></a></li>
                            </ul> */}
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <div class="card rounded-3">
                        <div class="team-img">
                            <img decoding="async" src={Team3} class="img-fluid" />
                        </div>
                        <div class="team-info pt-4 text-center">
                            <h5>Santa Binte</h5>
                            <p>Chief Technical Officer</p>
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Team Section Exit  --> */}

    {/* <!-- Gallery section -->  */}
    <section id="gallery" class="gallery_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-5">
                    <h3>Our Gallery</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-6 mb-4">
                    <img decoding="async" src={Gallery1} class="w-100 h-100" />
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <img decoding="async" src={Gallery2} class="w-100 h-100" />
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <img decoding="async" src={Gallery3} class="w-100 h-100" />
                </div>
                <div class="col-md-4 col-sm-6 mb-4">
                    <img decoding="async" src={Gallery4} class="w-100 h-100" />
                </div>
                <div class="col-md-8 col-sm-6 mb-4">
                    <img decoding="async" src={Gallery5} class="w-100 h-100" />
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Gallery Section Exit --> */}

    {/* <!-- Testimonial section -->  */}
    <section id="testimonial" class="testimonial_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                    <h3 class="text-black">Testimonials</h3>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img decoding="async" src={Client} class="img-fluid" />
                        <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                        <p>CEO of Prime IT</p>
                    </div>
                </div>
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    <h4>Awesome Work</h4>
                    <p>“Our company has been fortunate to have the excellent services of Easy Comp Assist. The team equipped our company with the best tools and resources to be protected and run successfully without interruptions in our daily processes. They are there when we need them the most. Thank you!”</p>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Testimonial Section Exit --> */}

    {/* <!--Appointment section -->  */}
     <section class="appointment_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-8 col-10">
                    <h3>Providing IT assistance services across more than 20 industries throughout every state in the US.</h3>
                    <p className='text-white'>Managed IT Services:</p>
                    <p className='text-white'>Digital Marketing:</p>
                    <p className='text-white'>E-commerce Solutions:</p>
                     <a href="+18888301368" class="mt-5 main-btn fill-btn">24X7 Tollfree Support</a>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Appointment section Exit --> */}

    

    {/* <!-- FAQ section --> */}
    <section id="faqs" class="faq_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center mb-5">
                    <p class="faq_subtitle">We're here to help</p>
                    <h2 class="faq_title">Frequently asked questions</h2>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-sm-12 col-lg-8 mb-5 mb-lg-0">
                    <div class="accordion accordion-flush" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    1. How can I improve my device's speed and performance?
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                Customers often inquire about steps to enhance the speed of their laptops, PCs, and mobile devices, looking for optimization tips and tune-up services.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                2. What steps should I take if my computer is running unusually slow and programs keep crashing?
                            </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                If your computer is underperforming with symptoms like sluggishness and frequent program crashes, it could be due to various issues, including malware infection, overloaded system resources, or outdated drivers. First, run a comprehensive antivirus scan to rule out malware. Then, check for unnecessary programs running in the background that could be consuming system resources. Update your operating system and drivers. If the issue persists, consider consulting a professional to diagnose hardware problems or other deeper system issues.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                3. Can you assist with software installation and updates?
                            </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                Users commonly seek help with installing new software applications or updating existing ones, including troubleshooting installation issues.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                4. How can I recover lost or deleted data?
                            </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                Data recovery services are a common concern, with customers asking about retrieving lost files due to accidental deletion or hardware issues.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                5. What are the best practices for maintaining my device's health?
                            </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                Advice on regular maintenance routines and preventative measures to keep devices running efficiently is often requested, including software updates and physical cleaning tips.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-4 mb-5">
                    <img decoding="async" src={Faq} class="img-fluid" />
                </div>
            </div>
        </div>

        {/* <!-- Send message --> */}
        {/* <div class="free_trial">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-sm-12 col-lg-6 text-center text-lg-start">
                        <h2 class="free_title">Questions not listed above? Send us a message and we'll get back to you</h2>
                    </div>
                    <div class="col-sm-12 col-lg-6 text-center text-lg-end mt-4 mt-lg-0"> 
                        <a class="learn-more-btn" href="#contact"><i class="fa fa-rocket" aria-hidden="true"></i> Free Consultation</a> 
                    </div>
                </div>
            </div>
        </div> */}
    </section>
    {/* <!-- FAQs section exit --> */}

    {/* <!-- Footer section -->  */}
    <Footer />

    {/* <!-- Footer section exit --> */}


</body>
    </>
  )
}

export default Home