import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Trust1 from '../images/top-banner-img/trust-pilot.png'
import Trust2 from '../images/top-banner-img/bbb-logo.png'
import Trust3 from '../images/top-banner-img/mcafee.png'
import Form from '../components/Form'

const Privacy = () => {
  return (
    <>
    <Navbar />
    <section id="home" class="home">
        <div class="banner_wrapper wrapper">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 order-md-1 order-2">
                        <h3>Privacy and Policy </h3>
                        <h1>Tech Assistance</h1>
                        <p>for your pc & laptop instant support anytime, anywhere!</p>
                        {/* <a href="#" class="main-btn mt-4 fill-btn">Call Us for Complete Assistance</a> */}
                        {/* <a href="#" class="main-btn mt-4 ms-3">Learn More</a> */}
                    </div>
                    <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-5">
                        <Form />
                        {/* <div class="top-right-sec">
                            <div class="animate-img">
                                <img decoding="async" class="aimg1" src="./images/top-banner-img/woman-brush.png" />
                                <img decoding="async" class="aimg2" src="./images/top-banner-img/doctor.png" />
                            </div>
                            <img decoding="async" class="img-fluid ms-xl-5" src="./images/top-banner-img/top-right-img-1.png" />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        {/* <div class="wrapper pb-0">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust1} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★☆
                        </div>
                    </div>
                </div>
                        <h4>4.7 Trustpilot Rating</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust2} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★★
                        </div>
                    </div>
                </div>
                                <h4>5 BBB Rating</h4>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust3} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★★
                        </div>
                    </div>
                </div>
                                <h4>Approved from Trusted Site</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </section>


    <section id="testimonial" class="testimonial_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                    <h3 class="text-black">Privacy</h3>
                </div>
            </div>
            <div class="row align-items-center">
                {/* <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img decoding="async" src="./images/testimonial/client1.png" class="img-fluid" />
                        <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                        <p>CEO of Prime IT</p>
                    </div>
                </div> */}
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    <h4>Introduction</h4>
                    <p>This privacy policy governs your use of the website easycompassist.com, alongside Easy Comp Assist's services, websites, and applications ("Websites"). These platforms are owned and operated by Easy Comp Assist LLC, a Delaware corporation. This policy outlines how Easy Comp Assist collects, uses, and protects the personal information you provide on our Websites. It also explains your rights regarding the use of your personal data and how you can access and update this information.</p>
                    <p>Our Commitment: At Easy Comp Assist, we are dedicated to providing IT outsourcing services that empower companies to excel in their core operations. As a trusted IT partner, we aim to help companies manage IT costs, reduce employer-related risks, and alleviate the administrative burdens of IT management. To offer these comprehensive services, we collect certain personal information from the employees of our clients. We prioritize the privacy of our users. The personal information you entrust to us is used solely for the purposes outlined in this policy.</p>
                    <p>Scope of This Policy: This policy does not extend to information you might provide to third parties, including those linked to from our Websites. While we strive to ensure our partners maintain high privacy standards, we cannot be responsible for their practices.</p>
                    <p>Information Collection: Easy Comp Assist collects identifiable information such as your name, company name, address, and other contact details to provide our services effectively. This information is collected directly from you or your employer and is essential for the services we offer. By providing this information, you consent to its collection, use, and disclosure as detailed in this Privacy Policy. We also automatically collect data related to your device and usage when you use our services, but we do not track location-based information from your mobile device.</p>
                    <p>Use of Cookies and Tracking: We employ technologies like cookies and scripts for site administration, usage analysis, and to provide a personalized experience. You may control these through your browser settings, though some site features may be limited as a result.</p>
                    <p>Information Use: Your personal information is used to enhance our offerings, tailor content, resolve disputes, and for marketing purposes, always respecting your preferences and legal rights.</p>
                    <p>Children's Privacy: Our services are designed for adults, and we do not knowingly collect information from children under 16.</p>
                    <p>Data Sharing: We do not sell or trade your personal information. It may be disclosed under legal compulsion or to protect rights and safety. We ensure third-party services providers adhere to strict privacy standards.</p>
                    <p>Retention and Security: We retain your information as long as necessary to provide our services and comply with legal obligations. Despite our security measures, no online transmission can be guaranteed to be 100% secure.</p>
                    <p>Modifications to This Policy: Easy Comp Assist reserves the right to modify this policy. Continued use of our Websites after changes signifies acceptance of the new terms.</p>
                    <p>Contact Us: For more information or to update your personal data, please contact us at support@easycompassist.com.

</p>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </>
  )
}

export default Privacy