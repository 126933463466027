import React, { useState } from 'react'
import './PopupForm.css'
import Form2 from './Form2';

const PopupForm2 = () => {
    const [showForm, setShowForm] = useState(false);

    const toggleForm = () => setShowForm(!showForm);
  
    return (
      <div>
        <button onClick={toggleForm} className="main-btn mt-4 fill-btn">
          Subscribe
        </button>
        {showForm && (
          <div className="popup-overlay">
            <div className="popup-container">
              <Form2 />
              <button onClick={toggleForm} className="close-btn">
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

export default PopupForm2