import React from 'react';
import { Link } from 'react-router-dom'; // Import Link

const Footer = () => {
  return (
    <>
      <section id="contact" className="footer_wrapper wrapper"> {/* class changed to className */}
        <div className="container pb-3">
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-4">
              <h5>Easy Comp Assist</h5>
              <p className="ps-0">IT support services provide businesses with technical expertise, ensuring seamless operations, troubleshooting issues, maintaining infrastructure, and implementing the latest technological solutions for optimal performance and security.</p>
              <div className="contact-info">
              <ul className="list-unstyled p-0 text-white">
                  <li><i className="fa fa-home me-3"></i> 811 Heights Blvd Suite 4, Houston, TX 77007, United States</li> 
                  <li><i className="fa fa-phone me-3"></i>(888) 830-13688</li>
                  <li><i className="fa fa-envelope me-3"></i>info@easycompassist.com</li> 
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
              <h5>Disclaimer</h5>
              <p className="ps-0">Easy Comp Assist operates as an independent entity offering remote technical support for software, hardware, and various peripherals. Our technical expertise covers a broad range of third-party products worldwide. We hold no ties or affiliations with these third-party companies unless explicitly stated. If seeking warranties or permissions for software, hardware, and peripherals, kindly reach out to the respective third party. Comp Care Takers doesn't claim responsibility for third-party content accessible on or via our platform; users access and utilize such content at their own risk.</p>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
              <h5>Important Links</h5>
              <ul className="link-widget p-0">
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="/privacy">Privacy & Policy</Link></li>
                <li><Link to="/terms">Terms & Condition</Link></li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
              {/* <h5>Newsletter</h5>
              <div className="form-group mb-4">
                <input type="email" className="form-control bg-transparent" placeholder="Enter Your Email Here" />
                <button type="submit" className="main-btn rounded-2 mt-3 border-white text-white">Subscribe</button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container-fluid copyright-section">
          <p className="p-0">Copyright <Link to="/">© Easy Comp Assist.</Link> All Rights Reserved</p>
        </div>
      </section>
    </>
  )
}

export default Footer;
