import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Trust1 from '../images/top-banner-img/trust-pilot.png'
import Trust2 from '../images/top-banner-img/bbb-logo.png'
import Trust3 from '../images/top-banner-img/mcafee.png'
import Form from '../components/Form'

const Terms = () => {
  return (
    <>
    <Navbar />
    <section id="home" class="home">
        <div class="banner_wrapper wrapper">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 order-md-1 order-2">
                        <h3>Terms and Condition </h3>
                        <h1>Tech Assistance</h1>
                        <p>for your pc & laptop instant support anytime, anywhere!</p>
                        {/* <a href="#" class="main-btn mt-4 fill-btn">Call Us for Complete Assistance</a> */}
                        {/* <a href="#" class="main-btn mt-4 ms-3">Learn More</a> */}
                    </div>
                    <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-5">
                        <Form />
                        {/* <div class="top-right-sec">
                            <div class="animate-img">
                                <img decoding="async" class="aimg1" src="./images/top-banner-img/woman-brush.png" />
                                <img decoding="async" class="aimg2" src="./images/top-banner-img/doctor.png" />
                            </div>
                            <img decoding="async" class="img-fluid ms-xl-5" src="./images/top-banner-img/top-right-img-1.png" />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        {/* <div class="wrapper pb-0">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust1} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★☆
                        </div>
                    </div>
                </div>
                        <h4>4.7 Trustpilot Rating</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust2} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★★
                        </div>
                    </div>
                </div>
                                <h4>5 BBB Rating</h4>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust3} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★★
                        </div>
                    </div>
                </div>
                                <h4>Approved from Trusted Site</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </section>


    <section id="testimonial" class="testimonial_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center mb-4">
                    <h3 class="text-black">Terms</h3>
                </div>
            </div>
            <div class="row align-items-center">
                {/* <div class="col-xl-3 col-md-4 col-sm-6">
                    <div class="card text-center">
                        <img decoding="async" src="./images/testimonial/client1.png" class="img-fluid" />
                        <h4 class="pt-4 mb-0">Aliceano Colby</h4>
                        <p>CEO of Prime IT</p>
                    </div>
                </div> */}
                <div class="col-xl-9 col-md-8 col-sm-6 ps-md-4 pt-sm-0 pt-4">
                    <h4>Introduction</h4>
                    <p>LEGAL AGREEMENT. This document, henceforth referred to as the "Terms," "Agreement," or "Contract," represents a legally binding contract. By engaging with it, you ("You" or "Your") acknowledge having read, understood, and agreed to all its provisions. These Terms govern Your access to and use of www.easycompassist.com and Easy Comp Assist’s digital platforms, including desktop and mobile websites ("Website(s)" or "Site(s)"), applications, and all forms of communication such as emails, chats, instant messaging, voice communications, etc., collectively referred to as "Platforms." These Platforms are owned and/or operated by Easy Comp Assist, Inc. or its subsidiaries ("Company," "Us," "We," "Our"). Accessing or using any Services or Platform signifies your agreement and is considered your written agreement, electronic signature, and intent to be legally bound by these Terms.</p>
                    <p>SCOPE OF AGREEMENT. This Agreement outlines the terms under which You can access our Platforms and use the human resource management resources and other related services provided by Easy Comp Assist through these Platforms. By using any Platform or registering for our Services, you agree to this legally binding contract between you and the Company. This Agreement applies to all users, including those accessing Platforms on behalf of an entity, its employees, or individually.</p>
                    <p>USER ELIGIBILITY. By engaging with our Services or Platforms, you affirm that you are at least 18 years of age, reside in the United States of America, and have the legal capacity to enter into contracts. If you represent an entity, you warrant that you have the authority to bind that entity to this Agreement.</p>
                    <p>AMENDMENTS AND MODIFICATIONS. The Company reserves the right to modify these Terms at any time without prior notice. Continued use of our Services or Platforms following any modifications signifies your acceptance of the revised Terms.</p>
                    <p>ELECTRONIC CONTRACTING. Your interaction with our Platforms, including passive use or active engagement such as clicking the "Continue" button, constitutes your electronic signature and intent to be bound by these Terms, which are entered into electronically.</p>
                    <p>PRIVACY AND DATA PROTECTION. Your use of our Platforms is also subject to the Easy Comp Assist Privacy Policy and any other terms accepted upon registration, all of which are incorporated into this Agreement by reference.</p>
                    <p>SERVICE USE AND PLATFORM LICENSING. Easy Comp Assist grants you a revocable, non-exclusive license to access and use our Platforms in accordance with these Terms. You agree not to modify, reverse engineer, or create derivative works from any Platform without our express written consent.</p>
                    <p>FEEDBACK AND CONTENT USE. Any feedback or content you provide to Easy Comp Assist becomes our property, and we have the royalty-free right to use, share, and create derivative works from such feedback for any purpose.</p>
                    <p>COPYRIGHT AND INTELLECTUAL PROPERTY. The material on our Platforms cannot be copied, reproduced, or distributed without express permission, except as explicitly allowed by these Terms.</p>
                    <p>By utilizing any Easy Comp Assist Platform or Service, you agree to comply with these Terms. This document supersedes all previous agreements regarding the use of our Platforms.</p>
                    <p>For a detailed understanding of your rights and obligations under this Agreement, please read it carefully. Accessing our Platforms following any modifications to the Terms indicates your agreement to be bound by such changes.</p>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    </>
  )
}

export default Terms