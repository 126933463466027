import React, { useState } from 'react';
import Form from './Form';
import './PopupForm.css'

const PopupForm = () => {
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => setShowForm(!showForm);

  return (
    <div>
      <button onClick={toggleForm} className="main-btn mt-4">
        Submit The Form For Assistance
      </button>
      {showForm && (
        <div className="popup-overlay">
          <div className="popup-container">
            <Form />
            <button onClick={toggleForm} className="close-btn">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupForm;
