import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Trust1 from '../images/top-banner-img/trust-pilot.png'
import Trust2 from '../images/top-banner-img/bbb-logo.png'
import Trust3 from '../images/top-banner-img/mcafee.png'
import Form from '../components/Form'
import About1 from '../images/about/about-banner1.png'

const About = () => {
  return (
    <>
    <Navbar />

    <section id="home" class="home">
        <div class="banner_wrapper wrapper">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 order-md-1 order-2">
                        <h3>About Us </h3>
                        <h1>Tech Assistance</h1>
                        <p>for your pc & laptop instant support anytime, anywhere!</p>
                        {/* <a href="#" class="main-btn mt-4 fill-btn">Call Us for Complete Assistance</a> */}
                        {/* <a href="#" class="main-btn mt-4 ms-3">Learn More</a> */}
                    </div>
                    <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-5">
                        <Form />
                        {/* <div class="top-right-sec">
                            <div class="animate-img">
                                <img decoding="async" class="aimg1" src="./images/top-banner-img/woman-brush.png" />
                                <img decoding="async" class="aimg2" src="./images/top-banner-img/doctor.png" />
                            </div>
                            <img decoding="async" class="img-fluid ms-xl-5" src="./images/top-banner-img/top-right-img-1.png" />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        {/* <div class="wrapper pb-0">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust1} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★☆
                        </div>
                    </div>
                </div>
                        <h4>4.7 Trustpilot Rating</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust2} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★★
                        </div>
                    </div>
                </div>
                                <h4>5 BBB Rating</h4>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust3} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★★
                        </div>
                    </div>
                </div>
                                <h4>Approved from Trusted Site</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </section>


    <section id="about" class="about_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mb-mb-0 mb-5">
                    <div class="position-relative">
                        <img decoding="async" src={About1} class="img-fluid" />
                        {/* <img decoding="async" src={About2} class="about-animate" /> */}
                    </div>
                </div>
                <div class="col-md-6 text-center text-md-start">
                    <h2>With Easy Comp Assist as your IT partner, you will get:</h2>
                    <p>Anticipatory IT Maintenance: Easy Comp Assist vigilantly oversees your tech infrastructure to pre-empt issues, ensuring consistent operational flow and reducing interruptions.</p>
                    <p>Accessible Expert Knowledge: Our IT experts stand by, offering swift and adept resolutions to tech queries, ensuring your business's tech needs are met promptly..</p>
                    <p>Customized Technological Planning: Recognizing each business's distinct needs, Easy Comp Assist devises bespoke IT strategies that support your specific objectives and expansion plans.</p>
                    <p>Innovative Tech Advancement: With Easy Comp Assist, embrace the forefront of technology, equipping your enterprise with leading-edge tools and innovations for a competitive edge.</p>
                    <div class="mt-5 card">
                        <div class="about-clinic">
                            <h4>Laptop/PC<br></br> Assist</h4>
                            
                        </div>
                        <div class="about-clinic">
                            <h4>iOS/Android Assist</h4>
                            
                        </div>
                        <div class="about-clinic">
                            <h4>Tablets<br></br> Assist</h4>
                            
                        </div>
                        <div class="about-clinic">
                            <h4>Printer <br></br> Assist</h4>
                            
                        </div>
                        <span class="line-1"></span>
                        <span class="line-2"></span>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <Footer />
    </>
  )
}

export default About