import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Trust1 from '../images/top-banner-img/trust-pilot.png'
import Trust2 from '../images/top-banner-img/bbb-logo.png'
import Trust3 from '../images/top-banner-img/mcafee.png'
import Form from '../components/Form'
import About1 from '../images/about/about-banner1.png'

const Contact = () => {
  return (
    <>
    <>
    <Navbar />

    <section id="home" class="home">
        <div class="banner_wrapper wrapper">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 order-md-1 order-2">
                        <h3>Contact Us </h3>
                        <h1>Tech Assistance</h1>
                        <p>for your pc & laptop instant support anytime, anywhere!</p>
                        {/* <a href="#" class="main-btn mt-4 fill-btn">Call Us for Complete Assistance</a> */}
                        {/* <a href="#" class="main-btn mt-4 ms-3">Learn More</a> */}
                    </div>
                    <div class="col-md-6 order-md-2 order-1 mb-md-0 mb-5">
                        
                        {/* <div class="top-right-sec">
                            <div class="animate-img">
                                <img decoding="async" class="aimg1" src="./images/top-banner-img/woman-brush.png" />
                                <img decoding="async" class="aimg2" src="./images/top-banner-img/doctor.png" />
                            </div>
                            <img decoding="async" class="img-fluid ms-xl-5" src="./images/top-banner-img/top-right-img-1.png" />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
        {/* <div class="wrapper pb-0">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust1} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★☆
                        </div>
                    </div>
                </div>
                        <h4>4.7 Trustpilot Rating</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust2} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★★
                        </div>
                    </div>
                </div>
                                <h4>5 BBB Rating</h4>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6 mb-4">
                        <div class="card text-center">
                            <div class="icon-box">
                                <img decoding="async" src={Trust3} />
                            </div>
                            <div>
                            <div class="rating-block">
                    <div class="rating-number">
                        <div class="stars yellow-stars">
                            ★★★★★
                        </div>
                    </div>
                </div>
                                <h4>Approved from Trusted Site</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </section>


    <section id="about" class="about_wrapper wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-6 mb-mb-0 mb-5">
                    <Form />
                </div>
                <div class="col-md-6 text-center text-md-start">
                    <h2>Contact Info</h2>
                    <div className="contact-info">
                <ul className="list-unstyled p-0">
                  <li><i className="fa fa-home me-3"></i> 811 Heights Blvd Suite 4, Houston, TX 77007, United States</li> {/* Removed Link for static address */}
                  <li><i className="fa fa-phone me-3"></i>(888) 830-1368</li>
                  <li><i className="fa fa-envelope me-3"></i>info@easycompassist.com</li> {/* Removed Link for static email */}
                </ul>
              </div>
                </div>
            </div>
        </div>
    </section>


    <Footer />
    </>
    </>
  )
}

export default Contact