import React from 'react';
import './Form.css';
import { useNavigate } from 'react-router-dom';

const Form = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform any necessary form processing here

    // Redirect to the 'thankyou' page
    navigate('/thankyou');
  };

  return (
    <>
      <form className="contact-form" onSubmit={handleSubmit}>
        <h2 className="form-heading">Get Unlimited IT Assistance</h2>
        <div className="form-group name-icon">
          <input type="text" className="form-control" placeholder="Name" name="name" required />
        </div>
        <div className="form-group email-icon">
          <input type="email" className="form-control" placeholder="Email" name="email" required />
        </div>
        <div className="form-group phone-icon">
          <input type="tel" className="form-control" placeholder="Phone Number" name="phone" required />
        </div>
        <button type="submit" className="main-btn mt-4">Submit</button>
      </form>
    </>
  );
};

export default Form;
